<template>
  <div>
    <b-card no-body>
      <b-card-body class="px-0">
        <vue-good-table
          ref="customers-table"
          :columns="columns"
          :rows="activeUsersList"
          :rtl="false"
          :small="true"
          styleClass="vgt-table condensed"
          @on-selected-rows-change="selectionChanged"
          :select-options="{
            enabled: false,
            disableSelectInfo: true,
            selectOnCheckboxOnly: true
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <router-link :to="'/admin/user-accounts/details/' + props.row.id">
                <b-button
                  size="sm"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  style="padding: 2px 6px !important; margin: auto"
                >
                  <unicon name="ellipsis-v" width="18"></unicon>
                </b-button>
              </router-link>
            </span>
            <span v-if="props.column.field == 'index'">
              {{ props.row.originalIndex + 1 }}
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
    <b-col cols="12" class="d-flex justify-content-center">
      <b-pagination
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        :value="UsersFirstPage"
        :total-rows="users.length"
        :per-page="UsersPageLength"
        @input="(value) => updateUsersPagination(value)"
      >
        <template #prev-text>
          <unicon width="20" name="angle-right" fill="#A41F02" />
        </template>
        <template #next-text>
          <unicon width="20" name="angle-left" fill="#A41F02" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import { BButton, BCol, BCard, BCardBody, BPagination } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BButton,
    BCol,
    BCard,
    BCardBody,
  },
  data() {
    return {
      isNotSelectedRows: true,
      columns: [
        {
          label: "#",
          field: "index",
        },
        {
          label: "اسم المستخدم",
          field: "username",
        },
        {
          label: "الاسم الكامل",
          field: "fullName",
        },
        {
          label: "الصلاحية",
          field: "role",
        },
        {
          label: "التفاصيل",
          field: "action",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.fetchUsers();
  },
  computed: {
    ...mapGetters(["users", "rolesList", "activeUsersList","UsersFirstPage","UsersPageLength"]),
  },
  methods: {
    ...mapActions(["fetchUsers", "updateUsersPagination"]),
    selectionChanged() {
      this.isNotSelectedRows = !this.$refs["customers-table"].selectedRows.length;
    },
    getSelectedRow() {
      let rowIds = this.$refs["customers-table"].selectedRows.map((row) => row.id);
      return rowIds;
    },
  },
};
</script>
